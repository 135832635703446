import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_DASHBOARD } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import Head from 'Components/core/Head';

export default class AdminDashboard extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return (
            <StyledComponent styles={require('./styles')}>
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_DASHBOARD.path),
                        label: 'Dashboard',
                    }]}
                >
                    <Head title="Dashboard - Admin" />
                </PageContent>
            </StyledComponent>
        );
    }
}
